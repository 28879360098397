<template>
  <div class="absolute bottom-5 left-10 w-72 p-4 bg-bggt flex flex-col rounded-lg max-h-popup z-99" id="popupModal">
    <BaseIcon name="close" class="self-end" outline= false @click="closeModal"/>
    <h4 class="font-heading text-h4 text-white">{{ $t('login.cookiePolicyTitle') }}</h4>
    <p class="font-body text-bodyM text-white pt-2 pb-4 overflow-auto" v-html="translate.policyText"></p>
    <Button :text="$t('login.acceptCP')" buttonType="primary" fullWidth class="self-center pb-2" @click="closeModal"/>
    <Button :text="$t('login.declineCP')" buttonType="secondary" fullWidth class="self-center" @click="closeModal"/>
  </div>
</template>

<script>

import Button from '@/components/Button.vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'Modal',
  components: {
    Button,
    BaseIcon
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  computed: {
    translate() {
      return {
        policyText: this.$t('login.cookiePolicyText'),
      }
    }
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  methods: {
    closeModal: function() {
      // document.querySelector("#app").classList.remove("opacity-30");
      // document.querySelector("#app").classList.remove("dark:opacity-30");
      // document.querySelector("#popupModal").classList.remove("opacity-100");
      // document.querySelector("#popupModal").classList.remove("dark:opacity-100");
      document.querySelector("#popupModal").classList.add("hidden");
    } 
  }
}
</script>
