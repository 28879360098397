<template>
    <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
        <Header :language="lang" 
            @langchanged="langchanged_"
            noPin>
        </Header>
        <div class="mx-6 text-center">
            <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white pt-14">{{ $t('login.register') }}</h4>
            <p class="font-body text-bodyM text-sepiaBlack dark:text-white pt-8 regtext">{{ $t('login.registerText') }}</p>
            <Input :placeholder="$t('login.email')" type="email" name="email" id="email" required />
            <Input :placeholder="$t('login.name')" type="text" id="name" name="name"/>
            <p class="font-body text-bodyS text-redDamask dark:text-redDamask pt-9 pb-4 termstext">{{ $t('login.policyText1') }} 
                <a href="#" class="text-halfColonialWhite underline" @click="openModal">{{ $t('login.cookiePolicy') }} </a> {{ $t('login.policyText2') }}</p>

            <Button :disabledBtn="btnDisabel" xl :text="stringTranslations.register" buttonType="primary" fullWidth v-on:click="quickLogin" />
            <Button href="/login" :disabledBtn="btnDisabel" xl :text="stringTranslations.login" buttonType="secondary" fullWidth />
        </div>
        <Modal class="hidden" />
    </div>
</template>

<script>

import Header from "@/components/mobile/Header.vue";
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import Modal from '@/components/mobile/Modal.vue';

export default {
    name: "QuickLogin",
    components: {
        Header,
        Button,
        Input,
        Modal
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            email: '',
            name: '',
            btnDisabel: false,
        }
    },
    computed: {
        stringTranslations() {
            return {
                register: this.$t('login.register'),
                login: this.$t('login.login'),
            }
        }
    },
    mounted() {
        this.$i18n.locale = this.lang;
    },
    methods: {
        quickLogin() {
            this.btnDisabel = true;
            this.email = document.querySelector('#email').value;
            this.name = document.querySelector('#name').value;
            const variabels = {language: this.lang, email: this.email, name: this.name};
            this.axios.post(this.baseURL + `/wp-json/wp/v2/quick-login`, variabels, {})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    console.log(response_data.error);
                    this.btnDisabel = false;
                } else {
                    const login_user = {username: response_data.email, password: response_data.pin, name: response_data.name}
                    try{
                        const loginResult = this.$store.dispatch('login', login_user);
                        loginResult.then(() => {
                            this.$router.push('/thank-you');
                            this.btnDisabel = false;
                        }).catch((e) => {
                            this.btnDisabel = false;
                            console.log(e)
                        });
                        // const { redirect = false } = this.$route.query;
                        // const path = redirect ? decodeURI(redirect) : '/thank-you';
                    }catch(e){
                        console.log("Error:");
                        console.log(e)
                        this.btnDisabel = false;
                    }
                }
                    
            }).catch( (error) => {
                console.log(error);
                this.btnDisabel = false;
            });
            
        },
        
        langchanged_(value) {
            this.lang = value;
        },
        openModal() {
            // document.querySelector("#app").classList.add("opacity-30");
            // document.querySelector("#app").classList.add("dark:opacity-30");
            // document.querySelector("#popupModal").classList.add("opacity-100");
            // document.querySelector("#popupModal").classList.add("dark:opacity-100");
            document.querySelector("#popupModal").classList.remove("hidden");
        }
    },
};
</script>
